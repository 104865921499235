import AppRouter from "./routers/Router";

function App() {


  return (

    <AppRouter />
  );
}

export default App;
