import "./style.scss";

const ButtonOptions = () => {
    return (
        <div className="button-options">
            <img src="./../../../../../icons/options.svg" />
        </div>
    );
};

export default ButtonOptions;
